import React from 'react';
// import sendyLogo from './mug.png';
import mazeLogo from './rage.png';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="App-container">
        <div className="title">Husqy Labs</div>
        <p className="subtext">
          we have ideas. we just don't do shit.
        </p>
        {/* <a
          className="App-link"
          target="_blank"
          href="mailto:cnr@husqylabs.com"
        >
          contact us
        </a> */}
        <div className="logo-wrapper">
          {/* <img src={sendyLogo} className="App-logo" alt="sendy" /> */}
          <a href='https://www.mazerage.com' target='_blank' rel="noreferrer">
            <div className="App-logo-wrapper">
              <img src={mazeLogo} alt="maze-rage" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
